import React from 'react'
//import {Spinner} from 'reactstrap'
// import { Button } from 'antd'
import './loading.css';


export default function HomeLoad(props) {

    return (
    <div className='loading-home'>

    {props.try_again && 
    (<div className='match-detail-loading-main'>
      
      <div className='loading-message'> Failed to retrieve data. Please try again.</div>
      <button className='loading-home-tryagain' onClick={props.try_again} >Try again</button>
    </div>) || 

    <div className='match-detail-loading-main'>
      <img src='/Spinner.svg' />
      <div className='loading-message'>
      Please wait few more seconds. We are retrieving league, match and odd data. 
      </div>
    </div> }
    
  </div>)

}